import { Link } from "react-router-dom";
import "../styles/NavStyles.css";
import AOS from "aos";
import { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";

const NavBar = props => {
  const { items } = props;
  const [navLinkOpen, setNavLinkOpen] = useState(true);

  useEffect(() => {
    AOS.init();
  });

  const toggleNav = () => {
    setNavLinkOpen(!navLinkOpen);
    console.log(navLinkOpen);
  };

  const renderClasses = () => {
    let classes = "nav-links";

    if (navLinkOpen) {
      classes += " active";
    }
    console.log(classes);
    return classes;
  };

  const renderTitleClass = () => {};
  return (
    <div className="navigation-container">
      <Link
        className="title"
        data-aos="fade-right"
        data-aos-duration="2000"
        to="/"
        style={{ textDecoration: "none" }}
      >
        <h1
          style={{
            fontSize: "20px",
            fontWeight: 300,
            color: "white",
            maxWidth: "30px",
            letterSpacing: "1.5px"
          }}
        >
          {" "}
          DAVID HARIN
        </h1>
      </Link>
      <div
        data-aos="fade-left"
        data-aos-duration="2000"
        className="menu-options"
      >
        <div className={renderClasses()}>
          <Link
            style={{
              textDecoration: "none",
              color: "white",
              paddingLeft: "30px",
              letterSpacing: "1.5px",
              fontSize: "18px",
              fontWeight: 300
            }}
            to="/"
          >
            home
          </Link>
          {items.map(item => (
            <Link
              style={{
                textDecoration: "none",
                color: "white",

                letterSpacing: "1.5px",
                fontSize: "18px",
                fontWeight: 300
              }}
              to={"./" + item.toLowerCase()}
            >
              {item}
            </Link>
          ))}
        </div>
        <div className="burger" onClick={toggleNav}>
          <BiMenuAltRight style={{ color: "white", fontSize: "30px" }} />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
