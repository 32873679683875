import "../styles/Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div></div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>Designed by Deborah David </p>
        <a
          href="https://lk.linkedin.com/in/deborah-david-3501181a0"
          target="_blank"
        >
          <FaLinkedinIn style={{ color: "gray" }} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
