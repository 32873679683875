import "../styles/Home.css";
import bgimage from "../assets/DSC09546.JPG";
import { FaFacebookF, FaTwitter, FaInstagram, FaSpotify } from "react-icons/fa";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos";

const Home = () => {
  const [cursorX, setCursorX] = useState();
  const [cursorY, setCursorY] = useState();

  window.addEventListener("mousemove", e => {
    setCursorX(e.pageX);
    setCursorY(e.pageY);
  });

  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="intro-banner">
      <Helmet>
        <title>DAVID HARIN - home</title>
      </Helmet>
      <div className="banner-overlay">
        <div className="banner-content">
          <div className="navigation">
            <div className="menu">
              <a href="/portfolio">PORTFOLIO</a>
              <a href="/services">SERVICES</a>
              <a href="/contact">CONTACT</a>
            </div>
            <div
              className="cursor"
              style={{ left: cursorX + "px", top: cursorY + "px" }}
            ></div>
            <div className=" social-menu">
              <a
                href="https://www.facebook.com/profile.php?id=100004837965791"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/davidharinproductions/"
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a
                href="https://open.spotify.com/artist/4ko8HjK1IS59gaCyfu9CT7"
                target="_blank"
              >
                <FaSpotify />
              </a>
              <a href="http://twitter.com/harindavid" target="_blank">
                <FaTwitter />
              </a>
            </div>
            <h1 className="banner-title">DAVID HARIN</h1>
            <p className="banner-desc">PRODUCER | MIXING ENGINEER | MUSICIAN</p>
            {/* <p className="banner-desc2">
        {" "}
        Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p> */}
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-once="true"
              className="discover-button"
            >
              <Link
                style={{
                  textDecoration: "none",
                  padding: "10px 50px",
                  border: "1px solid white"
                }}
                to="/portfolio"
              >
                discover
              </Link>
            </div>
          </div>
        </div>
        <img src={bgimage} style={{ width: "100%" }} />
        {/* <video
    id="videoBG"
    autoPlay
    muted
    loop
    style={{
      width: "100%",
      height: "80%",
      objectFit: "cover",
      zIndex: -2,
      position: "relative"
    }}
  >
    <source src={bgvideo} type="video/mp4" />
  </video> */}
      </div>
    </div>
  );
};

export default Home;
