import NavBar from "./NavBar";
import serviceImg from "../assets/DSC09392.JPG";
import "../styles/Service.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { fadeIn, fadeInUp } from "react-animations";
import styled, { keyframes } from "styled-components";
import portfolioLinkImg from "../assets/DSC09391.JPG";
import { FaFacebookF } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitter, FaSpotify } from "react-icons/fa";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import AOS from "aos";

const fadeAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 4s ${fadeAnimation};
`;

const fadeUpAnimation = keyframes`${fadeInUp}`;
const FadeInUpDiv = styled.div`
  animation: 2s ${fadeUpAnimation};
`;

const Services = () => {
  useEffect(() => {
    AOS.init();
  });
  const services = [
    {
      title: "Production",
      desc:
        "If you need someone to produce your song, need musicians locally or internationally to play on your song, or just simply need help with putting all the pieces together, just hit the contact page. "
    },
    {
      title: "Mixing",
      desc:
        " If you have tracks to your songs, but don’t know what to do with them, or how to go about putting everything in its place, hit me up and let’s start getting a mix! "
    },
    {
      title: "Live | Post Broadcast Mixing",
      desc:
        "  If you need your live broadcast to sound the best it can be when it is streamed online, I offer broadcast and or post production mixing."
    },
    {
      title: "Tracking",
      desc:
        "I also offer instrument tracking for songs. If you need Keys/Synth, acoustic guitar, or percussive elements on your track, I’d love to play on it. "
    }
  ];
  return (
    <div>
      <Helmet>
        <title>DAVID HARIN - services</title>
      </Helmet>
      <NavBar items={["portfolio", "contact"]} />
      <div className="services-container">
        <FadeInDiv>
          <h1 className="service-title">SERVICES</h1>
        </FadeInDiv>

        <FadeInDiv>
          <img src={serviceImg} style={{ margin: "0 auto" }} />
        </FadeInDiv>

        <div className="contact-desc">
          <p data-aos="fade-up" data-aos-duration="2000" data-aos-once="true">
            Being a singer/muscian and songwriter for the last many years, I
            know the importance of having quality tracks right from the get go.
            My goal is to bring out the best in your tracks so it reaches its
            fullest potential before it is released into the world. Whether
            you’re just starting off as a singer songwriter, a band, or have
            many years of expertise in the music industry, it would be an honour
            to work with you and bring to life the vision you have for your
            single, EP or album.
          </p>
        </div>

        <FadeInUpDiv>
          <div className="grid-container">
            <div className="service-items">
              {services.map(service => {
                return (
                  <div
                    className="service-item"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h1>{service.title}</h1>
                    <p>{service.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </FadeInUpDiv>
        <div className="portfolio-link-container">
          <div
            className="port-img"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <img src={portfolioLinkImg} />
          </div>
          <div
            className="port-background"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-once="true"
          ></div>
          <div className="link-content">
            <p data-aos="fade-in" data-aos-duration="2000" data-aos-once="true">
              If you’d like to hear some of the projects I've produced and mixed
              on
            </p>
            <br></br>
            <Link to="./portfolio" className="port-link">
              Click Here
            </Link>
          </div>
        </div>
      </div>

      <div className="contact-desc end">
        <div
          className="service-social"
          style={{
            marginTop: "50px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <a
            href="https://www.facebook.com/profile.php?id=100004837965791"
            target="_blank"
          >
            <FaFacebookF style={{ width: "30px", height: "20px" }} />
          </a>
          <a
            href="https://www.instagram.com/davidharinproductions/"
            target="_blank"
          >
            <RiInstagramFill
              style={{
                width: "30px",
                height: "25px",
                marginLeft: "5px"
              }}
            />
          </a>
          <a href="http://twitter.com/harindavid" target="_blank">
            <FaTwitter
              style={{
                width: "30px",
                height: "20px",
                marginLeft: "5px"
              }}
            />
          </a>
          <a
            href="https://open.spotify.com/artist/4ko8HjK1IS59gaCyfu9CT7"
            target="_blank"
          >
            <FaSpotify
              style={{
                width: "30px",
                height: "20px",
                marginLeft: "5px"
              }}
            />
          </a>
        </div>
        <p style={{ width: "70%" }}>
          I’d love to partner with you on this journey. Contact me by clicking
          the contact button below this and I'll get back to you as soon as
          possible. Feel free to check me out on Facebook and Instagram. Looking
          forward to hearing from you!
          <br />
        </p>
        <div style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Link to="./contact" className="contact-btn">
            contact
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
