import NavBar from "./NavBar";
import Footer from "./Footer";
import "../styles/Contact.css";
import Form from "./ContactForm";
import { FaFacebookF, FaInstagram, FaSpotify } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import contactImg from "../assets/DSC09412.JPG";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";

const Contact = () => {
  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>DAVID HARIN - contact</title>
      </Helmet>
      <NavBar items={["portfolio", "services"]} />
      <div className="contact-container">
        <div className="title-container">
          <p
            data-aos="fade-right"
            data-aos-duration="2000"
            className="contact-title"
          >
            Have a project?
          </p>
          <p
            data-aos="fade-right"
            data-aos-duration="3000"
            className="contact-me"
          >
            Contact me.
          </p>
        </div>
        <div>
          <div className="contact-body">
            <Form />

            <img src={contactImg} data-aos="fade-up" data-aos-duration="2000" />
          </div>
          <div className="socials">
            <a
              href="https://open.spotify.com/artist/4ko8HjK1IS59gaCyfu9CT7"
              target="_blank"
            >
              <FaSpotify />
            </a>
            <a
              href="https://www.instagram.com/davidharinproductions/"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100004837965791"
              target="_blank"
            >
              <FaFacebookF />
            </a>
            <a href="http://twitter.com/harindavid" target="_blank">
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
