import "../styles/Form.css";
import { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import AOS from "aos";
import Modal from "react-awesome-modal";

const ContactForm = () => {
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const sendEmail = e => {
    console.log("email sent ");

    e.preventDefault();

    emailjs
      .sendForm(
        "service_uv2oh8j",
        "template_kvehnvu",
        e.target,
        "user_FOxuOO1EJmvxsUcS2SDZm"
      )
      .then(
        result => {
          console.log(result.text);
          openModal();
        },
        error => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Modal
        visible={visible}
        width="400"
        height="200"
        effect="fadeInUp"
        style={{ marginLeft: "100px" }}
        onClickAway={() => closeModal()}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <h1>Sent!</h1>
          <p>Your message has been recieved.</p>
          <a
            style={{ color: "black" }}
            href="javascript:void(0);"
            onClick={() => closeModal()}
          >
            Close
          </a>
        </div>
      </Modal>
      <div
        className="form-container"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <form onSubmit={sendEmail}>
          <div className="form-row-1">
            <div className="label-container">
              <input type="text" name="fname" autoComplete="off" required />
              <label for="fname" className="label-name">
                <span className="content-name">First Name</span>
              </label>
            </div>
            <div className="label-container">
              <input type="text" name="lname" autoComplete="off" required />
              <label for="lname" className="label-name">
                <span className="content-name">Last Name</span>
              </label>
            </div>
          </div>
          <div className="label-container long form-row">
            <input type="text" name="email" autoComplete="off" required />
            <label for="email" className="label-name">
              <span className="content-name">Email Address</span>
            </label>
          </div>
          <p>What's your project about?</p>
          <div className="label-container long form-row text-area">
            <textarea
              id="details"
              type="text"
              name="details"
              autoComplete="off"
              required
              rows="10"
              cols="50"
            ></textarea>
          </div>
          <div className="label-container long form-row">
            <input type="text" name="projectname" autoComplete="off" required />
            <label for="projectname" className="label-name">
              <span className="content-name">Project Name</span>
            </label>
          </div>
          <div className="label-container long form-row">
            <input type="text" name="type" autoComplete="off" required />
            <label for="type" className="label-name">
              <span className="content-name">Type of Inquiry</span>
            </label>
          </div>
          <div>
            <input type="submit" className="submit-button" value="send" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
