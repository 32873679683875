import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import NotFoundPage from "./pages/404";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta
          name="description"
          content="Harin David. Whether
            you’re just starting off as a singer songwriter, a band, or have
            many years of expertise in the music industry, hit me up. I'd love
            to work with you and bring to life the vision you have for your
            single, EP or album."
        />
      </Helmet>

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
}

export default App;
