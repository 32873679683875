import NavBar from "./NavBar";
import Footer from "./Footer";
import "../styles/Portfolio.css";
import portImg1 from "../assets/one.JPG";
import portImg2 from "../assets/two.JPG";
import portImg3 from "../assets/three.JPG";
import portImg4 from "../assets/four.png";
import portImg5 from "../assets/five.JPG";
import portImg6 from "../assets/six.JPG";
import portImg7 from "../assets/seven.JPG";
import portImg8 from "../assets/eight.JPG";
import portImg9 from "../assets/nine.JPG";
import portImg10 from "../assets/ten.JPG";
import portImg11 from "../assets/eleven.JPG";
import portImg12 from "../assets/Papa.JPG";
import portImg13 from "../assets/Magic.PNG";
import portImg14 from "../assets/Man Adarei.JPG";
import portImg15 from "../assets/whisperinghope.JPG";
import portImg16 from "../assets/House Of Mercy.JPG";
import portImg17 from "../assets/ICAN.JPG";
import portImg18 from "../assets/Eres.JPG";
import portImg19 from "../assets/Cristo Viene.JPG";
import portImg20 from "../assets/God Of Breakthrough.webp";
import portImg21 from "../assets/Breathing Slow.JPG";
import portImg22 from "../assets/Varshavakse.JPG";
import portImg23 from "../assets/En Libertad.JPG";
import portImg24 from "../assets/Christ Be Magnified.JPG";
import portImg25 from "../assets/New Eyes.JPG";
import portImg26 from "../assets/Duology.JPG";
import portImg27 from "../assets/Enthan Kanmalai.JPG";
import portImg28 from "../assets/Come To Save.JPG";
import portImg29 from "../assets/As You Are.JPG";
import portImg30 from "../assets/The Infinite.JPG";
import portImg31 from "../assets/Senehasa Uthura.PNG";
import portImg32 from "../assets/Hymn For Ceylon.PNG";
import portImg33 from "../assets/Here to Stay.JPG";
import portImg34 from "../assets/Story In My Mind.JPG";
import portImg35 from "../assets/Through The Cracks.PNG";
import portImg36 from "../assets/Homecoming.webp";
import { useEffect } from "react";
import { FaSpotify, FaYoutube } from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import { Helmet } from "react-helmet";
import AOS from "aos";

const Portfolio = () => {
  const portfolioItems = [
    {
      img: portImg1,
      title: " No Measure",
      artist: "Lily Fernando & David Harin",
      extra: "Produced and Mixed by David Harin, Mastering by Jonathan Berlin",
      appleLink:
        "https://music.apple.com/us/album/no-measure-feat-harin-dharmanandan-single/1551222125",
      spotifyLink:
        "https://open.spotify.com/track/44PYJHB5ixXEpsLOVKZhrk?si=d9dbb92839bc4b11",
    },
    {
      img: portImg3,
      title: "Eikons",
      artist: "Shiern Peters",
      extra: "Produced and Mixed by David Harin, Mastering by Jonathan Berlin",
      appleLink: "https://music.apple.com/us/album/eikons/1536463230",
      spotifyLink:
        "https://open.spotify.com/album/2LctyOf9LKJSdL4boaaDfl?si=IixYIN_zT6e0ZzDop42ndQ",
    },
    {
      img: portImg7,
      title: "Father",
      artist: "Tani Polansky",
      extra: "Produced Tani Polensky, Mixed by David Harin",
      appleLink: "https://music.apple.com/gb/album/father-single/1519415999",
      spotifyLink:
        "https://open.spotify.com/track/1c4h0qNpkNHurxNxDtxpGE?si=a02497221de946d1",
    },
    {
      img: portImg4,
      title: "Here & Now",
      artist: "Peter & Erin Casey",
      extra: "Produced and Mixed by David Harin, Mastering by Joe Creppell",
      appleLink: "https://music.apple.com/lk/album/here-now-ep/1568251691",
      spotifyLink:
        "https://open.spotify.com/album/6jkr2n6odIgM50ftDzvah6?si=AWk2ZrQWQHC9l23eiy2miA",
    },

    {
      img: portImg5,
      title: "You are",
      artist: "Lily Fernando",
      extra:
        "Produced by David Harin, Mixed by Paul Arend, Mastering by Jonathan Berlin",
      appleLink: "https://music.apple.com/lk/album/you-are-single/1439390352",
      spotifyLink:
        "https://open.spotify.com/track/6GDoH1ChrNyainCHgsmogM?si=c27086099c584168",
    },
    {
      img: portImg6,
      title: "Into The Rhythm",
      artist: "Anu Madhubhashine",
      extra: "Produced and Mixed by David Harin, Mastering by Adam Sisco",
      appleLink:
        "https://music.apple.com/us/album/in-to-the-rhythm/1521818032?i=1521818035",
      spotifyLink:
        "https://open.spotify.com/track/2QnYnE0Pbvftb1h1pYnlOk?si=29641ad8d87244ad",
    },
    {
      img: portImg9,
      title: "More (Closer)",
      artist: "Peter Hath",
      extra: "Mixed by David Harin",
      appleLink:
        "https://music.apple.com/us/album/more-closer/1574231095?i=1574231097",
      spotifyLink:
        "https://open.spotify.com/track/036f9eXEmU7CdWQTIGSQHu?si=d0e57b1344d44351",
    },
    {
      img: portImg11,
      title: "Won't Let Me Down",
      artist: "Ethan De Silva & Ophelia Balasingh",
      extra: "Produced and Mixed by David Harin",
      appleLink:
        "https://music.apple.com/lk/album/wont-let-me-down/1577599789?i=1577599790",
      spotifyLink:
        "https://open.spotify.com/track/7hERLEG6ZqDpYQBt71xzud?si=26e1c0f6747840e6",
    },
    {
      img: portImg8,
      title: "Adorn",
      artist: "Stefan Mahendra",
      extra: "Produced and Mixed by David Harin",
      youtubeLink: "https://youtu.be/j3hRXdI4uFk",
    },
    {
      img: portImg2,
      title: "Reign Above it All",
      artist: "Kenyon Reed",
      extra: "Produced Kenyon Reed & David Harin, Mixed by David Harin",
      youtubeLink: "https://youtu.be/YweSAFU8-MY",
    },
    {
      img: portImg12,
      title: "Papa",
      artist: "Peniel Music",
      extra: "Produced by Dan Appadoo, Mixed & Mastered by me",
      appleLink: "https://music.apple.com/us/artist/peniel-music/1641735864",
      spotifyLink: "https://open.spotify.com/album/3FWZjHuSCZQ1MEDBjsaCYU",
      youtubeLink: "https://www.youtube.com/watch?v=XKjYXDXDZvQ",
    },
    {
      img: portImg13,
      title: "Magic",
      artist: "Eric Heinrichs",
      extra: "Mixed by me, Produced by Peter Hath, Mastered by Jonathan Berlin",
      appleLink:
        "https://music.apple.com/us/album/magic-feat-peter-hath-single/1676336964",
      spotifyLink: "https://open.spotify.com/track/7uwWM4YYJUUVi7TU7x1xBH",
      youtubeLink: "https://www.youtube.com/watch?v=kQBP6ZyZnBI",
    },
    {
      img: portImg14,
      title: "Man Adarei",
      artist: "HMG Band",
      extra:
        "Produced by Shalom Samuel, Mixed by me, Mastered by Steven Borrelli",
      appleLink:
        "https://music.apple.com/mu/album/man-adarei-single/1608289282",
      spotifyLink: "https://open.spotify.com/album/4OsEsoDinlOe2gNq8qVZZP",
      youtubeLink: "https://www.youtube.com/watch?v=acINc2ryugk",
    },
    {
      img: portImg17,
      title: "I Can Do All Things",
      artist: "Joshua Alailima",
      extra: "Produced by Stefan Blessing, Mixed by me",
      appleLink:
        "https://music.apple.com/us/album/i-can-do-all-things-single/1656262784",
      spotifyLink: "https://open.spotify.com/album/6D5Cal8sm0hZ1z8HbTsfCy",
      youtubeLink: "https://www.youtube.com/watch?v=oGs2OimUP8Y",
    },
    {
      img: portImg15,
      title: "Whispering Hope",
      artist: "Gresha Schuilling, Pio Anandappa, Shimron Fernando",
      extra: "Produced & Mixed by me, Mastered by Luke Pimentel",
      appleLink: "https://music.apple.com/lc/album/whispering-hope/1642498004",
      spotifyLink: "https://open.spotify.com/album/0wnBgIl3j1kW6yCGFiGXD9",
      youtubeLink: "https://www.youtube.com/watch?v=G_QuhPIj6kY",
    },
    {
      img: portImg16,
      title: "House Of Mercy",
      artist: "Aleena Marnee",
      extra: "Produced by Chris Hardy, Mixed by Me, Mastered by Drew Lavyne",
      appleLink:
        "https://music.apple.com/us/album/house-of-mercy-single/1658604217",
      spotifyLink: "https://open.spotify.com/track/2jYSz2IQ84CmVivJG7CmVP",
      youtubeLink: "https://www.youtube.com/watch?v=KD4Y5sxHPuM",
    },
    {
      img: portImg18,
      title: "Eres (Live)",
      artist: "Elegidos",
      extra: "Mixed & Mastered by me",
      appleLink: "https://open.spotify.com/album/2oyZdyafuLMQwfEPzBjCg0",
      spotifyLink: "https://music.apple.com/ca/album/eres-single/1656151313",
      youtubeLink: "https://www.youtube.com/watch?v=dC_3rSJu4vg",
    },
    {
      img: portImg19,
      title: "Cristo Viene",
      artist: "Corban Worship",
      extra:
        "Produced by David Rosario, Mixed by me, Mastered by Luke Pimentel",
      appleLink:
        "https://music.apple.com/us/album/cristo-viene-single/1650315533",
      spotifyLink: "https://open.spotify.com/artist/3Fxhpq6C19tWGBnbXGUPRQ",
      youtubeLink: "https://www.youtube.com/watch?v=zsgxWRcD2as",
    },
    {
      img: portImg20,
      title: "God of Breakthrough",
      artist: "Shannon Diaz",
      extra: "Produced, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/in/album/god-of-breakthrough-single/1640434588",
      spotifyLink: "https://open.spotify.com/album/7eVpfc7CrdeMs32ddikLWH",
      youtubeLink: "https://www.youtube.com/watch?v=48BKfIX6Qxk",
    },
    {
      img: portImg21,
      title: "Breathing Slow",
      artist: "Peter Hath",
      extra: "Produced by Peter Hath, Mixed by me, Mastered by Drew Lavyne",
      appleLink:
        "https://music.apple.com/sn/album/breathing-slow-single/1641303050",
      spotifyLink: "https://open.spotify.com/track/7JRWNREvCZS12A1YTkvXwc",
      youtubeLink: "https://www.youtube.com/watch?v=lNElh2Te9BE",
    },
    {
      img: portImg22,
      title: "Varshavakse",
      artist: "Voice Band (Youth Revival)",
      extra: "Produced by Shalom Samuel, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/ag/album/varshavakse-single/1634685751",
      spotifyLink: "https://open.spotify.com/track/2RCZgq2hIbk4qaV5pxEoyZ",
      youtubeLink: "https://www.youtube.com/watch?v=x-cLtupdOQ0",
    },
    {
      img: portImg23,
      title: "En Libertad",
      artist: "Tracy Medina",
      extra: "Produced by Cales Louima, Mixed by me, Mastered by Drew Lavyne",
      appleLink:
        "https://music.apple.com/nz/album/en-libertad-single/1626044717",
      spotifyLink: "https://open.spotify.com/track/337yjNGNxYqNuKLWuM4sHh",
      youtubeLink: "https://www.youtube.com/watch?v=Qlae-R760Qc",
    },
    {
      img: portImg24,
      title: "Christ Be Magnified",
      artist: "Encounter Praise",
      extra:
        "Produced by Devan Bumstead, Mixed by Luke Hendrickson, Mastered by Drew Lavyne. I Played Aux Keys & Synth on the track 'Living Water'  ",
      appleLink:
        "https://music.apple.com/us/album/christ-be-magnified-live/1612880113",
      spotifyLink: "https://open.spotify.com/track/1ZcG08UBeFKN0sN8eauga3",
      youtubeLink: "https://www.youtube.com/watch?v=fkRpQuKLxVY",
    },
    {
      img: portImg25,
      title: "New Eyes",
      artist: "Peter Hath",
      extra: "Produced by Peter Hath, Mixed by me, Mastered by Drew Lavyne",
      appleLink:
        "https://music.apple.com/us/album/new-eyes-ocean-wave-version-single/1610187195",
      spotifyLink: "https://open.spotify.com/album/7qGfQreX8axuTeTWhAisNE",
      youtubeLink: "https://www.youtube.com/watch?v=zQIUmjmOCbc",
    },

    {
      img: portImg26,
      title: "Duology",
      artist: "Shiern Peters",
      extra: "Produced by Shiern Peters, Mixed by me, Mastered by Drew Lavyne",
      appleLink: "https://music.apple.com/nz/album/duology-ep/1607373317",
      spotifyLink: "https://open.spotify.com/album/13zlw5wb5fVvts89BcYUjO",
      youtubeLink:
        "https://www.youtube.com/playlist?app=desktop&list=OLAK5uy_ncTX12CSYvPKYm2s-mFVezcRRAw4D6fFY",
    },
    {
      img: portImg36,
      title: "Homecoming II (Live)",
      artist: "Sam Daniel",
      extra: "Produced by Sam Daniel, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/tr/album/homecoming-ii-live/1550232020",
      spotifyLink: "https://open.spotify.com/album/5HiCECoJui2jY6Tdik60PU",
    },
    {
      img: portImg27,
      title: "Enthan Kanmalai",
      artist: "Jonathan & Karen",
      extra: "Produced & Mixed by me, Mastered by Matthew Delemos",
      spotifyLink: "https://open.spotify.com/track/3JUz7Snt1gkP8SftjkUSGh",
      youtubeLink: "https://www.youtube.com/watch?v=1PYP6kn4BNg",
    },
    {
      img: portImg28,
      title: "Come To Save",
      artist: "Peter Hath",
      extra: "Produced by Peter Hath, Mixed by me, Mastered by Jonathan Berlin",
      appleLink:
        "https://music.apple.com/cd/album/come-to-save-single/1601698270",
      spotifyLink: "https://open.spotify.com/track/1gBCzUVFQLbVAZOKEuai9I",
      youtubeLink:
        "https://www.youtube.com/watch?v=OWehAUFBD54&list=OLAK5uy_nxTGpe9xL7o4pKjpd4FmKV6COGKND3k1A",
    },
    {
      img: portImg29,
      title: "As You Are",
      artist: "Peter Hath",
      extra: "Produced by Peter Hath, Mixed by me, Mastered by Jonathan Berlin",
      appleLink:
        "https://music.apple.com/na/album/as-you-are-feat-peter-hath-single/1593479095",
      spotifyLink: "https://open.spotify.com/track/0UQjwhwvnuDky9o48kn6RG",
      youtubeLink: "https://www.youtube.com/watch?v=zEK9cQP3cDI",
    },
    {
      img: portImg30,
      title: "The Inifinite",
      artist: "Peter Hath",
      extra: "Produced by Peter Hath, Mixed by me, Mastered by Jonathan Berlin",
      appleLink:
        "https://music.apple.com/us/album/the-infinite-single/1578602106",
      spotifyLink: "https://open.spotify.com/track/7ec81uRPFA602uWnXAaYTq",
      youtubeLink: "https://www.youtube.com/watch?v=i3oFHxvbais",
    },
    {
      img: portImg31,
      title: "Senehasa Uthura",
      artist: "Shalom Samuel & Sanduni Gunasekara",
      extra: "Produced by Shalom Samuel, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/lk/album/senehasa-uthura-feat-david-harin-sanduni-gunasekara/1658397824",
      spotifyLink: "https://open.spotify.com/track/4ZSiu6jfvZInPS7zqRIRqD",
      youtubeLink: "https://www.youtube.com/watch?v=aiAAjmoXuwM",
    },
    {
      img: portImg32,
      title: "Hymn for Ceylon",
      artist: "Josh K",
      extra:
        "Produced by Josh K & David Harin, Mixed by me, Mastered by Steven Borrelli",
      appleLink:
        "https://music.apple.com/jm/album/hymn-for-ceylon-single/1643540243",
      spotifyLink: "https://open.spotify.com/track/40avatQglWBusN1bzB7VjK",
    },
    {
      img: portImg33,
      title: "Here to Stay",
      artist: "Dinel Udage",
      extra: "Produced, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/us/album/here-to-stay-single/1637117228",
      spotifyLink: "https://open.spotify.com/track/6vVSmac7fUelv21NYJYj9o",
      youtubeLink: "https://www.youtube.com/watch?v=Rf_mfDCHoqE",
    },
    {
      img: portImg34,
      title: "Story In My Mind",
      artist: "Cheneliza Wickramathilaka & Dinel Udage",
      extra: "Produced, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/us/album/story-in-my-mind-single/1649409587",
      spotifyLink: "https://open.spotify.com/track/55JXuA6K3kPzbxyhr0hpig",
      youtubeLink: "https://www.youtube.com/watch?v=Y1S4zpRtw78",
    },
    {
      img: portImg35,
      title: "Through the Cracks",
      artist: "Dinel Udage",
      extra: "Produced, Mixed & Mastered by me",
      appleLink:
        "https://music.apple.com/us/album/through-the-cracks-single/1654149011",
      spotifyLink: "https://open.spotify.com/track/1OZhhaR7xq0hb2CDVhIox4",
      youtubeLink: "https://www.youtube.com/watch?v=BmycnST_Kak",
    },
  ];

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>DAVID HARIN - portfolio</title>
      </Helmet>
      <NavBar items={["services", "contact"]} />

      <div className="portfolio-container">
        <h1
          className="portfolio-title"
          data-aos="fade-in"
          data-aos-duration="3000"
        >
          {" "}
          PORTFOLIO
        </h1>
        <div className="wrapper">
          {portfolioItems.map((portfolio) => (
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-once="true"
              className="portfolio-item"
            >
              <div className="img-container">
                <img src={portfolio.img} />
              </div>
              <div className="desc-container">
                <h1>{portfolio.title}</h1>
                <p className="artist">{portfolio.artist}</p>
                <p className="port-extra">{portfolio.extra}</p>

                <div className="listen-btn">
                  {portfolio.spotifyLink && (
                    <a
                      href={portfolio.spotifyLink}
                      target="_blank"
                      style={{ color: "grey" }}
                    >
                      <FaSpotify
                        style={{
                          width: "30px",
                          height: "30px",
                          marginLeft: "5px",
                        }}
                      />
                    </a>
                  )}
                  {portfolio.appleLink && (
                    <a
                      href={portfolio.appleLink}
                      target="_blank"
                      style={{ color: "grey" }}
                    >
                      <SiApplemusic
                        style={{
                          width: "30px",
                          height: "30px",
                          marginLeft: "5px",
                          marginLeft: "15px",
                        }}
                      />
                    </a>
                  )}
                  {portfolio.youtubeLink && (
                    <a
                      href={portfolio.youtubeLink}
                      target="_blank"
                      style={{ color: "grey" }}
                    >
                      <FaYoutube
                        style={{
                          width: "35px",
                          height: "35px",
                          marginLeft: "15px",
                        }}
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
